
 
  
  import {
    defineComponent,
    onMounted,
    onUpdated,
    Ref,
    ref,
watch,
  } from "vue";
  import { Modal } from "bootstrap";
  import Api from "@/services/Api";
  import useAlert from "@/composables/Alert";
  import Button from "@/components/Button.vue"
  import { useRoute } from "vue-router";
  import { useLoaderStore } from "@/store/LoaderStore";

  
  export default defineComponent({
    name: "ModalUploadProdutos",
    components: {
      Button,
    },
    emits: ["attProdutos", "closeModal"],
    props: { 
        productsList: Object ,
        closeModal: Function,
        getProdutosLoja: Function
      },
  
    setup(props, { emit }) {
      const { showTimeAlert } = useAlert();
      const auxModal: Ref<any> = ref('');
      const fileInput: Ref<any> = ref(null);
      const spanWarning = ref(false);
      const productList: Ref<any> = ref([]);
      const isCreate: Ref<boolean> = ref(false);
      const dialogVisible: Ref<boolean> = ref(false);
      const idLoja: Ref<any> = ref(null)
      const messageError: Ref<any> = ref('')
      const route = useRoute();
      const loaderStore = useLoaderStore();

    
  
      const openInput = () => fileInput.value.click();

      async function enviaPlanilha(){
        const response = await Api.post('rodaPlanilha', {idLoja: idLoja.value, produtos: productList.value})
        if(response.status === 'error'){
          productList.value = response.produtos
          dialogVisible.value = false
          messageError.value = response.message
          return showTimeAlert('Erro ao salvar produtos!', "error")
        }else {
          props.closeModal && props.closeModal()
          props.getProdutosLoja && props.getProdutosLoja()
          dialogVisible.value = false
          messageError.value = ''
        }
      }
      
      function catchIdParams() {
        idLoja.value = route.query.storeId;
      }

      function trataProductList(array){
        const produtosTratados = array.map((product: any)=> {
          return {
            codProdutoLoja: product['Código do produto'],
            produto: product['Descrição'],
            preco: product['Preço'],
            estoque: product['estoque'],
            grupoExibicao: product['grupo'],
            unidade: product['unidade'],
            informacao_extra: product['informacao_extra'],
            sku: product['sku']
          }
        })
        productList.value = produtosTratados
        messageError.value = ''
        loaderStore.close()
      }

      
      watch(
        () => props.productsList,
        () => trataProductList(props.productsList)
        )
        
        onMounted(() => {
          catchIdParams()
      })
     
      return {
        isCreate,
        dialogVisible,
        spanWarning,
        openInput,
        fileInput,
        productList,
        enviaPlanilha,
        messageError
      };
    },
  });
  