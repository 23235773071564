

interface ModalTelaProduto { }

import { ref, onMounted, onUnmounted, defineEmits, watch, Ref, onBeforeMount, DefineComponent } from "vue";
import { defineComponent } from "vue";
import Api from "../../services/Api";
import s3 from '../../services/S3Service'
import useAlert from "../../composables/Alert";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import ModalTelaProduto from "./components/ModalTelaProduto.vue";
import ModalUploadProdutos from "./components/ModalUploadProdutos.vue";
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue";
import { useLoaderStore } from "@/store/LoaderStore";
import { Modal } from "bootstrap";
import * as XLSX from 'xlsx/xlsx.mjs';
import axios from "axios";

export default defineComponent({
  components: { 
    ModalTelaProduto: ModalTelaProduto as unknown as DefineComponent<{}, {}, ModalTelaProduto> ,
    ModalUploadProdutos, 
    Title,
    Button
  },
  name: "Produtos",

  setup() {

    const { showTimeAlert } = useAlert();
    const auxModal: Ref<any> = ref('');
    const arquivo: Ref<any> = ref();
    const router = useRouter();
    const route = useRoute();
    const idLoja: Ref<any> = ref(null);
    const produtos: Ref<any> = ref([])
    const paginasNumeradas: Ref<any> = ref([]);
    const filteredProducts: Ref<any> = ref([]);
    const productsToUpload: Ref<any> = ref([]);
    const loaderStore = useLoaderStore();
    // const productsToVerify: Ref<any> = ref([]);
    const inputFind: Ref<string> = ref('');
    const fileInput: Ref<any> = ref(null);
    const produto: Ref<any> = ref({})
    const paramsProdutos: Ref<any> = ref({ pagina: 1, limite: 20, input: '' })
    const openFind: Ref<boolean> = ref(false)
    const findActive: Ref<boolean> = ref(false)
    const totalProdutos: Ref<number> = ref(0)
    const isLoading: Ref<boolean> = ref(false)
    
    function catchIdParams() {
      idLoja.value = route.query.storeId;
    }

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = new Modal(auxElement);
      auxModal.value.show();
    };

    function closeModal() {
      auxModal.value.hide()
    }

    const getToken = async () => {
      return localStorage.getItem("token_usuario");
    };
    getToken();

    function buscarProduto(product: Object | null = null) {
      if (!product) {
        openModal('modalTelaProduto')
        return produto.value = {
          codConcessionaria: idLoja.value,
        }
      }
      produto.value = product
      openModal('modalTelaProduto')
    }

    function paginar(str: string) {
      if (str === '+') return paramsProdutos.value.pagina++
      paramsProdutos.value.pagina--
    }

    async function getProdutosLoja() {
      // if(findActive) state.value.currentPage = 1
      try {
        isLoading.value = true
        const headers = { 'idLoja': idLoja.value }
        const { data } = await axios.post("produtoPaginadoGestao", { ...paramsProdutos.value }, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            idLoja: idLoja.value
          },
        });
        produtos.value = data.produtos
        totalProdutos.value = data.totalProdutos
      } catch (error) {
        console.log(error);
      } finally {
        // findActive.value = false
        isLoading.value = false
      }
    }

    async function downloadProducts() {
      try {
        const res = await Api.get('baixarPlanilhaProdutos', {idLoja: idLoja.value},{ responseType: 'blob' })
        baixaArquivo(res, 'meus_produtos_ilub.xlsx')
      } catch (err) {
        console.log(err);
      }
    }

    function baixaArquivo(blobParam:any, name:string) {

      const url = URL.createObjectURL(new Blob([blobParam]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
      URL.revokeObjectURL(url);
    }

    const openInput = () => fileInput.value.click();

    const uploadProducts = async (event: any) => {
      const file = event.target.files[0];
      arquivo.value = file;
      getJsonFromXLSX()
    };

    async function getJsonFromXLSX() {
      if(arquivo.value){
        const fileReader = new FileReader();
        fileReader.onload = function(event){
          const data = event.target?.result;

          const workbook = XLSX.read(data, {
            type: "binary"
          });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            let cleanedRowObject = rowObject.map(row => {
            let cleanedRow = {};
            Object.keys(row).forEach(key => {
              let cleanedKey = key.trim();  // Remove espaços no início e no fim
              cleanedRow[cleanedKey] = row[key];
            });
            return cleanedRow;
          });

            let jsonObject = JSON.stringify(cleanedRowObject);
            productsToUpload.value = JSON.parse(jsonObject)
          })
        }
        fileReader.readAsBinaryString(arquivo.value)
        openModal('modalUploadProdutos')
        loaderStore.open();
      }
    }

    function filterProd(str: string) {
      filteredProducts.value = produtos.value.filter(objeto => {
        return Object.entries(objeto).some(([chave, valor]) => {
          if (typeof valor === 'string' && valor.includes(str)) {
            return true;
          }
          return false;
        });
      });
    }

    function usePagination() {
      const state = ref({
        currentPage: 1,
        itemsPerPage: 20,
        itemsOnPage: 0,
      });

      const pageCount = computed(() => Math.ceil(totalProdutos.value / state.value.itemsPerPage));

      const itemsOnPage = computed(() => {
        const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
        const endIndex = startIndex + state.value.itemsPerPage;
        return (filteredProducts.value.length ? filteredProducts.value : produtos.value).slice(startIndex, endIndex);
      });

      const setCurrentPage = page => {
        state.value.currentPage = page;
        state.value.itemsOnPage = itemsOnPage.value.length;
      };

      return {
        state,
        setCurrentPage,
        pageCount,
        itemsOnPage,
      };
    }

    const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();

    const numberPages = () => {
      for (let i = 1; i <= pageCount.value; i++) {
        paginasNumeradas.value = i;
      }
    };

    watch(
      () => pageCount.value,
      () => numberPages()
    )

    watch(
      () => idLoja.value,
      () => getProdutosLoja()
    )

    watch(
      () => state.value.currentPage,
      () => { paramsProdutos.value.pagina = state.value.currentPage, getProdutosLoja() }

    )

    

    onBeforeMount(() => catchIdParams());

    return {
      router,
      produtos,
      state,
      setCurrentPage,
      pageCount,
      itemsOnPage,
      numberPages,
      paginasNumeradas,
      inputFind,
      filterProd,
      filteredProducts,
      openModal,
      buscarProduto,
      produto,
      getProdutosLoja,
      auxModal,
      closeModal,
      idLoja,
      paramsProdutos,
      paginar,
      openFind,
      totalProdutos,
      findActive,
      fileInput,
      downloadProducts,
      openInput,
      uploadProducts,
      productsToUpload,
      isLoading
    };
  },
});
